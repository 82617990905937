export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Nissan-US',
  hostName: 'insight.nissanactivebuyerreferral.com',
  environmentName: 'production',
  appName: 'Nissan-US Analytics',
  ssoPassword: 'ShiftDigital#1',
  applicationInsights: {
    instrumentationKey: '160e0399-7d33-494c-a79b-b73b4cba32e3'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#8c8c8c', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://nissanus-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://nissanus-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://nissanus-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: 'https://retailertools.nissanactivebuyerreferral.com',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/nissan-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['ChatOverview']
};
