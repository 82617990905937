import { IRole, IRoleConfig } from '../../_shared/models/models-index';

export class RoleConfig implements IRoleConfig {

  systemAdministratorRoles: IRole[] = [
    { roleId: 1, name: 'System Administrator'}
  ];
  corporateRoles: IRole[] = [
    { roleId: 2, name: 'Corporate' }
  ];
  org4Roles: IRole[] = [];
  org5Roles: IRole[] = [];
  org3Roles: IRole[] = [
    { roleId: 8, name: 'Region Manager' }
  ];
  org2Roles: IRole[] = [
    { roleId: 12, name: 'Area Manager' }
  ];
  org1Roles: IRole[] = [
    { roleId: 10, name: 'District Manager' }
  ];
  dealerRoles: IRole[] = [
    { roleId: 11, name: 'Dealer' }
  ];
  dealerGroupRoles: IRole[] = [
    { roleId: 13, name: 'Dealer Group' }
  ];

}
